@font-face {
    font-family: "fontype-rounded";
    src: url('../fonts/FontypeRounded-rgJJ7.ttf');
}

.GalleryContainer{
    
    font-family: fontype-rounded;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.GalleryTitleContainer{
    
    width: fit-content;
    font-size: 40px;
}

.GalleryImageContainer{
    
    width: fit-content;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.OnlyImage{
    margin-top: 20px;
    width: 70%;
    min-width: 275px;
}

.image{
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
}
.forwardArrow{
    margin-left: 15px;
    width: 8%;
    height:auto;
    object-fit: contain;
    
    max-width: 50px;
    cursor: pointer;
    min-width: 30px;
}

.backArrow{
    margin-right: 15px;
    width: 8%;
    height:auto;
    object-fit: contain;
    min-width: 30px;
    max-width: 50px;
    cursor: pointer;
    transform: rotate(180deg);
}