@font-face {
    font-family: "fontype-rounded";
    src: url('../fonts/FontypeRounded-rgJJ7.ttf');
}

.AboutUsContainer{

    font-family: fontype-rounded;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.AboutUsTitleContainer{

    width: fit-content;
    font-size: 40px;
}

.AboutUsParagraphContainer{
    width: fit-content;
    font-size: 17px;
    max-width: 700px;
    margin: 0 10px 0 10px;
    text-align: center;
}