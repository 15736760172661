@font-face {
    font-family: "fontype-rounded";
    src: url('../fonts/FontypeRounded-rgJJ7.ttf');
}


.Header{
    width: 100%;
    height: 150px;
    
    justify-content: center;
    align-items: center;
    display: flex;
}

.GalleryHeaderContainer{
    display: flex;
    font-family: fontype-rounded;
    
    margin:7px
}

.LocationHeaderContainer{
    display: flex;
    font-family: fontype-rounded;
    
    width: fit-content;
    margin:7px
}

.logo{
    width:110px;
    
    height: auto;
}

.img-logo{
    width: 100%;
    height: 100%;
}

.Header p{
    margin: 10px;
    
    font-family: fontype-rounded;
    color: white;
}

.container {
  display: none;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 3px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 2px;
}

.change.bar1 {
    transform: translate(0, 9px) rotate(-45deg);
    background-color: black;
}
  
.change.bar2 {
    opacity: 0;
}
  
  .change.bar3 {
    transform: translate(0, -9px) rotate(45deg);
    background-color: black;
}

.sideBar{
    display: flex;
    position: fixed;
    flex-direction: column;
    z-index: 1;
    width: 0px;
    right: 0;
    top: 0;
    background-color: white;
    height:100%;
    transition-duration: .5s;
}

.sideContentContainer{
    
    margin-top: 125px;
    height: auto;
}

.sideBar p{
   display: none;
   margin: 30px;
   font-size: 30px;
   width: 0px;
   transition-duration: .5s;
   color: black;
}

.sideBarOn{
    display: none;
    position: fixed;
    flex-direction: column;
    z-index: 1;
    width: 175px;
    right: 0;
    top: 0;
    background-color: white;
    height:100%;
    transition-duration: .5s;
}

.sideBarOn p{
    display:block;
    transition-duration: .5s;
    margin: 30px 10px 30px 15px;
    font-size: 25px;
    transition-duration: .5s;
    cursor: pointer;
    color: black;
 }
  

@media screen and (width < 600px){

    .container {
        cursor: pointer;
        display: inline-block;
        
        position: absolute;
        right: 0;
        margin-right: 10px;
        z-index: 2;
      }
      .containerOn {
        cursor: pointer;
        display: inline-block;
        
        position: fixed;
        right: 0;
        margin-right: 10px;
        z-index: 2;
      }

      .sideBarOn{
        display: flex;
        position: fixed;
        flex-direction: column;
        z-index: 1;
        width: 175px;
        right: 0;
        top: 0;
        background-color: white;
        height:100%;
        transition-duration: .5s;
        box-shadow: 0 10px 20px black;
    }
      
    .GalleryHeaderContainer{
        display: none;
        font-family: fontype-rounded;
        
        margin:7px
    }
    
    .LocationHeaderContainer{
        display: none;
        font-family: fontype-rounded;
        
        width: fit-content;
        margin:7px
    }
}