@font-face {
    font-family: "fontype-rounded";
    src: url('../fonts/FontypeRounded-rgJJ7.ttf');
}

.LocationContainer{
    
    font-family: fontype-rounded;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.LocationTitleContainer{
    
    width: fit-content;
    font-size: 40px;
}

.LocationParagraphContainer{
    
    width: fit-content;
    font-size: 17px;
}

.LocationImageContainer{
    
    width: fit-content;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    justify-content: center;
}

.ImageContainer{
    width: 60%;
    min-width: 350px;
}

.KansasImage{
    width: 100%;
    border-radius: 20px;
}