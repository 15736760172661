.ImagePopContainer{
    display: flex;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    border-color: antiquewhite;
    background-color: rgba(0, 0, 0, .7);
    align-items:center;
    justify-content: center;
}

.PopContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.xContainer{
    
    width: 10%;
}

.PoppedImage{
    width: 99%;
    border-radius: 20px;
    max-width: 70%;
    min-width: 375px;
}

.x{
    width: fit-content;
    cursor: pointer;
    font-size: 30px;
    height:auto;
    color: aliceblue;
}