

.wrapper{
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow-x: hidden;
}

.img-header{
  width: max-content;
  height: auto;
  position: relative;
}

.SpaceBetweenVerseAndGallery{
  
  width: 100%;
  height: 35vw;

}

.SpaceBetweenGalleryAndLocation{
  
  width: 100%;
  height: 200px;

}