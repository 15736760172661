@font-face {
    font-family: "fontype-rounded";
    src: url('../fonts/FontypeRounded-rgJJ7.ttf');
}

.TitleContainer{
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: fontype-rounded;
}

.Title{
    
    font-size: 45px;
    margin: 40px 10px 1px 10px;
    color: white;
}

.Subtext{
    
    font-size: 18px;
    margin: 0px 10px 16px 10px;
    color: white;
}